import { useState, useMemo, useEffect, useCallback } from "react";
import "./App.css";
import Illustration from "./components/Illustration";
import CompanyModal from "./components/modals/CompanyModal";
import Splash from "./components/Splash";
import ContentBox from "./components/ContentBox";
import ContactModal from "./components/modals/ContactModal";

const companiesParams = {
  biner: {
    title: "Biner",
    description:
      "Biner es una empresa especializada en ofrecer soluciones en criptomonedas para empresas y personas.",
    link: "https://biner.com/",
  },
  trokor: {
    title: "Trokor",
    description:
      "Trokor desarrolla sistemas de seguimiento satelital de cargas. Combina su experiencia en software y hardware para ofrecer soluciones de rastreo y monitoreo de vehículos y cargas.",
    link: "https://trokor.com/",
  },
  blocktracker: {
    title: "BlockTracker",
    description:
      "BlockTracker es una empresa homologada por la Dirección Nacional de Aduanas para el precintado de cargas en tránsito. Su sistema de seguimiento satelital de cargas permite a las empresas de transporte y logística cumplir con la normativa vigente.",
    link: "https://blocktracker.com.uy/",
  },
  kicknerds: {
    title: "Kicknerds",
    description:
      "Kicknerds es una empresa de desarrollo de software especializada en soluciones integrales. Desarrolla tanto sistemas de uso masivo como aplicaciones para empresas de distintos rubros.",
    link: "https://kicknerds.com/",
  },
  millsonic: {
    title: "Millsonic",
    description:
      "Millsonic es una plataforma de música que permite personalizar la identidad musical de las empresas. Musicaliza locales en múltiples rubros como el retail, hoteles, restaurantes y oficinas.",
    link: "https://millsonic.com/",
  },
  entraste: {
    title: "Entraste.com",
    description:
      "Entraste.com es una plataforma para la venta de entradas online. Permite a los organizadores de eventos vender tickets y productos, y a sus clientes una experiencia fácil y segura de obtenerlos.",
    link: "https://entraste.com/",
  },
};

const modals = Object.keys(companiesParams).reduce((acc, key) => {
  acc[key] = ({ onClose, nextCompany, prevCompany, transition }) => (
    <CompanyModal
      onClose={onClose}
      nextCompany={nextCompany}
      prevCompany={prevCompany}
      params={companiesParams[key]}
      transition={transition}
    />
  );
  return acc;
}, {});

modals.contact = ({ onClose }) => (
  <ContactModal onClose={onClose} params={{}} />
);

function App() {
  const [ready, setReady] = useState(false);
  const [modal, setModal] = useState(null);
  const [doModalTransition, setDoModalTransition] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 2000);
  }, []);

  const Modal = useMemo(() => {
    if (modal && modals[modal]) {
      return modals[modal];
    }
    return null;
  }, [modal]);

  const handleSetModal = useCallback((modal) => {
    setModal(modal);
    setDoModalTransition(true);
  }, []);

  const handleNextCompany = () => {
    const keys = Object.keys(companiesParams);
    const nextCompanyIndex =
      (keys.indexOf(modal) - 1 + keys.length) % keys.length;
    setModal(keys[nextCompanyIndex]);
    setDoModalTransition(false);
  };

  const handlePrevCompany = () => {
    const keys = Object.keys(companiesParams);
    const prevCompanyIndex = (keys.indexOf(modal) + 1) % keys.length;
    setModal(keys[prevCompanyIndex]);
    setDoModalTransition(false);
  };

  console.log("App rerender");
  return (
    <div className={["App", modal ? `${modal}-modal` : ""].join(" ")}>
      <Splash visible={!ready} />
      {ready && (
        <>
          <Illustration openModal={handleSetModal} start={ready} />
          <div className="content">
            <ContentBox openModal={handleSetModal} />
          </div>
          {Modal && (
            <Modal
              onClose={() => handleSetModal(null)}
              nextCompany={handleNextCompany}
              prevCompany={handlePrevCompany}
              transition={doModalTransition}
            />
          )}
        </>
      )}
    </div>
  );
}

export default App;
